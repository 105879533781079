import Button from "../../components/Button";
import { Check } from "@mui/icons-material";

const SUBSCRIPTIONS = [
  {
    title: "Echo 1000",
    description: "",
    price: "$499.99",
    duration: "/1.000 dakika",
    features: ["Başlangıç için iyi fırsat!"],
  },
  {
    title: "Vocal 2000",
    description: "",
    price: "$939.99",
    duration: "/2.000 dakika",
    features: ["%6 İndirim"],
  },
  {
    title: "Connect 5000",
    description: "",
    price: "$2099.99",
    duration: "/5.000 dakika",
    features: ["%16 İndirim"],
  },
  {
    title: "CallPro 7500",
    description: "",
    price: "$2925.00",
    duration: "/7.500 dakika",
    features: ["%22 İndirim"],
  },
  {
    title: "MedVoice 10K",
    description: "",
    price: "$3599.99",
    duration: "/10.000 dakika",
    features: ["%28 İndirim"],
  },
];

const Subscription = () => {
  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {SUBSCRIPTIONS.map((subscription, i) => (
          <div key={i} className="border-2 bg-white p-5 rounded-lg">
            <h3 className="text-gray-700 font-bold">{subscription.title}</h3>
            <span className="text-sm text-gray-500">
              {subscription.description}
            </span>
            <div className="flex items-center my-5">
              <h4 className="text-gray-700 font-bold text-xl">
                {subscription.price}
              </h4>
              <span className="text-gray-500 text-xs">
                {subscription.duration}
              </span>
            </div>
            <ul className="my-4">
              {subscription.features.map((feature, i) => (
                <li className="flex items-center" key={i}>
                  <Check className="text-biens me-1" />
                  <span className="text-gray-600 text-sm">{feature}</span>
                </li>
              ))}
            </ul>
            <Button
              title="Satın Al"
              type="button"
              variant="outlined"
              size="sm"
              className="w-full"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Subscription;
