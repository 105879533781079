import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { Customer } from "./customersSlice";
import moment from "moment";

export interface CouponCode {
  id: string;
  title: string;
  desc: string;
  code: string;
}

export interface Product {
  id: string;
  name: string;
  photoUrl: string;
  url: string;
  price: string;
}

interface NewSocketMessage {
  role: "user" | "assistant";
  content: string;
  createdAt: Date;
  customerId: string;
  status: "sent" | "read";
  products?: Product[];
  couponCode?: CouponCode;
}

export interface Message {
  _id: string;
  role: "user" | "assistant";
  content: string;
  status: "sending" | "sent" | "read" | "error"; // 'error' and 'sending' for client only
  assistantId?: string;
  products?: Product[];
  coupon?: CouponCode;
  createdAt: Date;
}

export interface CONVERSATION_CUSTOMER {
  _id: string;
  name: string;
  lastView: Date;
  visitCount: number;
  email: string;
  phoneNumber: string;
  photoKey?: string;
  photoUrl?: string;
  createdAt: Date;
}

export interface Conversation {
  _id: string;
  assignedAgentId?: string;
  source: "web" | "whatsapp" | "instagram" | "messenger" | "telegram" | "phone";
  deviceType: "mobile" | "desktop";
  browserName?: string;
  conversationType: "text" | "call";
  status: "open" | "closed";
  callDuration?: number;
  language?: string;
  timezone?: string;
  country_code?: string;
  country_name?: string;
  city?: string;
  IPv4?: string;
  state?: string;
  lastMessage: Message;
  messages: Message[];
  customer: CONVERSATION_CUSTOMER;
  createdAt: Date;
}

export type SocketNewCustomer = {
  id: string;
  IPv4: string;
  browserName: string;
  city: string;
  companyId: string;
  country_code: string;
  country_name: string;
  deviceType: string;
  language: string;
  name: string;
  source: string;
  state: string;
  timezone: string;
  webIdentifier: string;
  webSocketId: string;
};

interface ConversationsState {
  conversations: Conversation[];
  waitList: string[];
  isLoading: boolean;
}

const initialState: ConversationsState = {
  conversations: [],
  waitList: [""],
  isLoading: true,
};

export const conversationsSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    initialSetConversations: (
      state,
      action: PayloadAction<{
        allConversation: Conversation[];
        assignedConversations: Conversation[];
      }>,
    ) => {
      const allConversations: Conversation[] = [];
      // Pushing all conversations
      action.payload.allConversation.map((conversation) => {
        allConversations.push({
          ...conversation,
          messages: conversation.messages.sort(
            (a, b) =>
              moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
          ),
        });
      });
      // Pushing assigned conversation if not exists in all conversation
      action.payload.assignedConversations.map((conversation) => {
        const checkIsExists = allConversations.find(
          (item) => item._id === conversation._id,
        );
        if (!checkIsExists) {
          allConversations.push({
            ...conversation,
            messages: conversation.messages.sort(
              (a, b) =>
                moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
            ),
          });
        }
      });
      state.conversations = allConversations.sort(
        (a, b) =>
          moment(b.lastMessage.createdAt).valueOf() -
          moment(a.lastMessage.createdAt).valueOf(),
      );
      state.isLoading = false;
    },
    setConversations: (state, action: PayloadAction<Conversation[]>) => {
      const tmpState = [...state.conversations];
      action.payload.map((conversation) => {
        let foundConversation = tmpState.find(
          (item) => item._id === conversation._id,
        );
        if (foundConversation) {
          // replace with new one
          foundConversation = conversation;
        } else {
          tmpState.push({
            ...conversation,
            messages: conversation.messages.sort(
              (a, b) =>
                moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
            ),
          });
        }
      });
      state.conversations = tmpState.sort(
        (a, b) =>
          moment(b.lastMessage.createdAt).valueOf() -
          moment(a.lastMessage.createdAt).valueOf(),
      );
      state.isLoading = false;
    },
    assignToAssistant: (
      state,
      action: PayloadAction<{ customerId: string; agentId: string }>,
    ) => {
      const tmpConversations = [...state.conversations];
      const foundConversation = tmpConversations.find(
        (item) => item._id === action.payload.customerId,
      );
      if (foundConversation) {
        foundConversation.assignedAgentId = action.payload.agentId;
      }
      state.conversations = tmpConversations;
    },
    setPreviousMessage: (
      state,
      action: PayloadAction<{ customerId: string; messages: Message[] }>,
    ) => {
      const tmpConversations = [...state.conversations];
      const foundConversation = tmpConversations.find(
        (item) => item._id === action.payload.customerId,
      );
      if (foundConversation) {
        const newMessages = [
          ...foundConversation.messages,
          ...action.payload.messages,
        ];
        foundConversation.messages = newMessages.sort(
          (a, b) =>
            moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
        );
      }
      state.conversations = tmpConversations;
    },
    sendMessage: (
      state,
      action: {
        payload: {
          _id: string;
          id: string;
          conversationId: string;
          content: string;
          products?: Message["products"];
        };
      },
    ) => {
      const tmpConversations = [...state.conversations];
      const foundConversation = tmpConversations.find(
        (item) => item._id === action.payload.conversationId,
      );
      if (foundConversation) {
        foundConversation.messages.unshift({
          _id: action.payload._id,
          role: "assistant",
          content: action.payload.content,
          createdAt: new Date(),
          status: "sending",
          products: action.payload.products,
        });
        foundConversation.lastMessage = {
          _id: action.payload._id,
          role: "assistant",
          content: action.payload.content,
          createdAt: new Date(),
          status: "sending",
          products: action.payload.products,
        };
      }
      state.conversations = tmpConversations;
    },
    updateMessageStatus: (
      state,
      action: PayloadAction<{
        messageId: string;
        conversationId: string;
        status: "sent" | "error";
        newMessageId?: string;
      }>,
    ) => {
      const tmpConversations = [...state.conversations];
      const foundConversation = tmpConversations.find(
        (item) => item._id === action.payload.conversationId,
      );
      if (foundConversation) {
        const foundMessage = foundConversation.messages.find(
          (item) => item._id === action.payload.messageId,
        );
        if (foundMessage) {
          if (action.payload.newMessageId) {
            foundMessage._id = action.payload.newMessageId;
          }
          foundMessage.status = action.payload.status;
          state.conversations = tmpConversations;
        }
      }
    },
    addSocketMessage: (state, action: PayloadAction<NewSocketMessage>) => {
      const tmpConversations = [...state.conversations];
      const foundConversation = tmpConversations.find(
        (item) => item._id === action.payload.customerId,
      );
      if (foundConversation) {
        const newMessage: Message = {
          _id: uuidv4(),
          role: action.payload.role,
          content: action.payload.content,
          createdAt: action.payload.createdAt,
          products: action.payload.products || [],
          status: action.payload.status,
        };
        foundConversation.messages.unshift(newMessage);
        foundConversation.lastMessage = newMessage;
      }
      state.conversations = tmpConversations;
    },
    setConversationRead: (state, action: PayloadAction<string>) => {
      const tmpConversations = [...state.conversations];
      const foundConversation = tmpConversations.find(
        (item) => item._id === action.payload,
      );
      if (foundConversation) {
        foundConversation.messages = foundConversation.messages.map((item) => ({
          ...item,
          status: "read",
        }));
      }
      state.conversations = tmpConversations;
    },
    addNewWebCustomer: (state, action: PayloadAction<Conversation>) => {
      const tmpConversations = [...state.conversations];
      tmpConversations.unshift(action.payload);
      state.conversations = tmpConversations;
    },
    addWaitList: (state, action: PayloadAction<string>) => {
      const tmpWaitList = [...state.waitList];
      tmpWaitList.push(action.payload);
      state.waitList = tmpWaitList;
    },
    removeWaitList: (state, action: PayloadAction<string>) => {
      const tmpWaitList = [...state.waitList];
      state.waitList = tmpWaitList.filter((item) => item !== action.payload);
    },
    saveNewCall: (state, action: PayloadAction<Conversation>) => {
      state.conversations.forEach((item) => {
        if (item.customer._id === action.payload.customer._id) {
          item.customer = action.payload.customer; // Directly updating customer
        }
      });
      state.conversations.unshift(action.payload);
    },
  },
});

export const {
  initialSetConversations,
  setConversations,
  assignToAssistant,
  sendMessage,
  setPreviousMessage,
  updateMessageStatus,
  addSocketMessage,
  setConversationRead,
  addNewWebCustomer,
  addWaitList,
  removeWaitList,
  saveNewCall,
} = conversationsSlice.actions;

export default conversationsSlice.reducer;
