import { Close } from "@mui/icons-material";
import { Customer } from "../../store/slices/customersSlice";
import { useEffect, useState } from "react";
import { REQUEST_GET_CUSTOMER_DETAIL } from "../../api/requests";
import { Conversation } from "../../store/slices/conversationsSlice";
import moment from "moment";

type Props = {
  isVisible: boolean;
  customerId: Customer["_id"];
  handleClose: () => void;
};

type CUSTOMER = {
  _id: string;
  name: string;
  phoneNumber: string;
  conversations: {
    callDuration: number;
    conversationType: Conversation["conversationType"];
    createdAt: Date;
    id: string;
    status: Conversation["status"];
  }[];
};

const ContactInfo = ({ isVisible, customerId, handleClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState<CUSTOMER>();

  useEffect(() => {
    if (isVisible) {
      setIsLoading(true);
      REQUEST_GET_CUSTOMER_DETAIL({ id: customerId })
        .then(({ data }) => {
          setCustomer(data.data.customer);
        })
        .finally(() => setIsLoading(false));
    } else {
      setCustomer(undefined);
    }
  }, [isVisible, customerId]);

  return (
    <div
      className={`fixed top-0 right-0 h-full w-full md:w-3/4 bg-white rounded-l-lg shadow-lg z-50 transform transition-transform duration-300 ease-in-out ${
        isVisible ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="p-4 flex justify-between items-center border-b">
        <h2 className="text-md font-medium">Contact Info</h2>
        <Close
          onClick={handleClose}
          className="text-gray-700 hover:bg-gray-300 rounded-lg transition  duration-200 hover:transition hover:duration-200 cursor-pointer"
        />
      </div>
      <div className="p-4">
        <p className="text-gray-700 text-sm">Name: {customer?.name}</p>
        <p className="text-gray-700 text-sm">Email: test@test.com</p>
        <p className="text-gray-700 text-sm">Phone: {customer?.phoneNumber}</p>
      </div>
      {customer?.conversations ? (
        <div className="p-4">
          {customer.conversations.map((conversation) => (
            <div key={conversation.id}>
              <div>{conversation.conversationType}</div>
              <div>{conversation.callDuration}</div>
              <div>{moment(conversation.createdAt).fromNow()}</div>
              <div>{conversation.status}</div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ContactInfo;
