import React, { useRef, useState } from "react";
import moment from "moment";
import { Conversation } from "../store/slices/conversationsSlice";
import {
  ChevronRight,
  ChevronLeft,
  MenuOpenOutlined,
} from "@mui/icons-material";
import Button from "./Button";
import { useTranslation } from "react-i18next";

type Props = {
  conversation: Conversation;
};
const CustomerInfo: React.FC<Props> = ({ conversation }) => {
  const { t } = useTranslation();
  const customerInfoDiv = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(true);

  const handleOpenClose = () => {
    if (customerInfoDiv.current) {
      const isOpen = customerInfoDiv.current.classList.contains(
        "animate-customer-profile-open",
      );
      if (isOpen) {
        customerInfoDiv.current.classList.remove(
          "animate-customer-profile-open",
        );
        customerInfoDiv.current.classList.add("animate-customer-profile-close");
        setIsOpen(false);
      } else {
        customerInfoDiv.current.classList.remove(
          "animate-customer-profile-close",
        );
        customerInfoDiv.current.classList.add("animate-customer-profile-open");
        setIsOpen(true);
      }
    }
  };

  return (
    <div
      ref={customerInfoDiv}
      className={`hidden lg:block h-screen overflow-y-auto bg-white w-0 animate-customer-profile-open border-l-2`}
    >
      <div
        className={`border-b-2 flex items-center min-h-[74px] max-h-[74px] ${isOpen ? "py-2 ps-5 pe-2 justify-between" : "justify-center"}`}
      >
        {isOpen ? (
          <h6 className="text-gray-700">{t("customer_profile")}</h6>
        ) : null}
        <Button
          type="button"
          variant="outlined"
          size="sm"
          className="items-center justify-center min-h-[36px] max-h-[36px] min-w-[36px] max-w-[36px] rounded-lg"
          title={<>{isOpen ? <MenuOpenOutlined /> : <MenuOpenOutlined />}</>}
          onClick={handleOpenClose}
        />
      </div>
      {isOpen ? (
        <div className="px-5 py-2 text-sm">
          <table className="table-fixed w-full">
            <tbody>
              <tr>
                <td className="text-gray-500 py-2 w-[30%]">
                  {conversation.source === "phone"
                    ? t("first_call")
                    : t("first_view")}
                </td>
                <td className="ps-2 overflow-hidden text-ellipsis">
                  {moment(conversation.customer.createdAt).fromNow()}
                </td>
              </tr>
              <tr className="border-t-[1px]">
                <td className="text-gray-500 py-2">
                  {conversation.source === "phone"
                    ? t("last_call")
                    : t("last_view")}
                </td>
                <td className="ps-2 overflow-hidden text-ellipsis">
                  {moment(conversation.customer.lastView).fromNow()}
                </td>
              </tr>
              <tr className="border-t-[1px]">
                <td className="text-gray-500 py-2">
                  {conversation.source === "phone"
                    ? t("total_call")
                    : t("total_visit")}
                </td>
                <td className="ps-2 overflow-hidden text-ellipsis">
                  {conversation.customer.visitCount}
                </td>
              </tr>
              <tr className="border-t-[1px]">
                <td className="text-gray-500 py-2">{t("language")}</td>
                <td className="ps-2 overflow-hidden text-ellipsis">
                  {conversation.language}
                </td>
              </tr>
              <tr className="border-t-[1px]">
                <td className="text-gray-500 py-2">{t("country")}</td>
                <td className="ps-2 overflow-hidden text-ellipsis">
                  {conversation.country_code}
                </td>
              </tr>
              {conversation.city || conversation.state ? (
                <tr className="border-t-[1px]">
                  <td className="text-gray-500 py-2">{t("state")}</td>
                  <td className="ps-2 overflow-hidden text-ellipsis">
                    {conversation.city}/{conversation.state}
                  </td>
                </tr>
              ) : null}
              {conversation?.timezone ? (
                <tr className="border-t-[1px]">
                  <td className="text-gray-500 py-2">{t("timezone")}</td>
                  <td className="ps-2 overflow-hidden text-ellipsis">
                    {conversation.timezone}
                  </td>
                </tr>
              ) : null}
              {conversation.source !== "phone" ? (
                <tr className="border-t-[1px]">
                  <td className="text-gray-500 py-2">{t("platform")}</td>
                  <td className="ps-2 overflow-hidden text-ellipsis">
                    {conversation.deviceType}
                  </td>
                </tr>
              ) : null}

              {conversation.browserName ? (
                <tr className="border-t-[1px]">
                  <td className="text-gray-500 py-2">{t("browser")}</td>
                  <td className="ps-2 overflow-hidden text-ellipsis">
                    {conversation.browserName}
                  </td>
                </tr>
              ) : null}
              {conversation.customer.email ? (
                <tr className="border-t-[1px]">
                  <td className="text-gray-500 py-2">{t("email")}</td>
                  <td className="ps-2 overflow-hidden text-ellipsis">
                    {conversation.customer.email}
                  </td>
                </tr>
              ) : null}
              {conversation.customer.phoneNumber ? (
                <tr className="border-t-[1px]">
                  <td className="text-gray-500 py-2">{t("phone")}</td>
                  <td className="ps-2 overflow-hidden text-ellipsis">
                    {conversation.customer.phoneNumber}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
};

export default CustomerInfo;
