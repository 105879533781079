import {
  DisplaySettings,
  DisplaySettingsOutlined,
  Forum,
  ForumOutlined,
  PeopleAlt,
  PeopleAltOutlined,
  QueryStats,
  QueryStatsOutlined,
  Widgets,
  WidgetsOutlined,
  CallOutlined,
  Call,
  ManageAccounts,
  FlashOnOutlined,
  Business,
  Psychology,
  Beenhere,
  DataUsage,
  ContactsOutlined,
  Contacts,
} from "@mui/icons-material";
import React from "react";

export const eCommerceMenuItems = [
  {
    titleKey: "analytics",
    icon: <QueryStatsOutlined />,
    activeIcon: <QueryStats className="text-biens" />,
    link: "/dashboard/analytics",
  },
  {
    titleKey: "messages",
    icon: <ForumOutlined />,
    activeIcon: <Forum className="text-biens" />,
    link: "/dashboard/messages",
  },
  {
    titleKey: "integrations",
    icon: <WidgetsOutlined />,
    activeIcon: <Widgets className="text-biens" />,
    link: "/dashboard/integrations",
  },
  {
    titleKey: "team",
    icon: <PeopleAltOutlined />,
    activeIcon: <PeopleAlt className="text-biens" />,
    link: "/dashboard/team",
  },
  {
    titleKey: "chat_config",
    icon: <DisplaySettingsOutlined />,
    activeIcon: <DisplaySettings className="text-biens" />,
    link: "/dashboard/chat-config",
  },
];

export const hospitalMenuItems = [
  {
    titleKey: "analytics",
    icon: <QueryStatsOutlined />,
    activeIcon: <QueryStats className="text-biens" />,
    link: "/dashboard/analytics",
  },
  {
    titleKey: "calls",
    icon: <CallOutlined />,
    activeIcon: <Call className="text-biens" />,
    link: "/dashboard/messages",
  },
  {
    titleKey: "contacts",
    icon: <ContactsOutlined />,
    activeIcon: <Contacts className="text-biens" />,
    link: "/dashboard/contacts",
  },
  {
    titleKey: "team",
    icon: <PeopleAltOutlined />,
    activeIcon: <PeopleAlt className="text-biens" />,
    link: "/dashboard/team",
  },
];

export const settingsECommerceMenuItems = [
  {
    labelKey: "settings_user",
    to: "/dashboard/settings",
    icon: <ManageAccounts />,
    activeIcon: <ManageAccounts className="text-biens" />,
  },
  {
    labelKey: "fast_replies",
    to: "/dashboard/settings/fast-replies",
    icon: <FlashOnOutlined />,
    activeIcon: <FlashOnOutlined className="text-biens" />,
  },
  {
    labelKey: "settings_company",
    to: "/dashboard/settings/company",
    icon: <Business />,
    activeIcon: <Business className="text-biens" />,
  },
  {
    labelKey: "settings_ai",
    to: "/dashboard/settings/ai",
    icon: <Psychology />,
    activeIcon: <Psychology className="text-biens" />,
  },
  {
    labelKey: "settings_subscription",
    to: "/dashboard/settings/subscription",
    icon: <Beenhere />,
    activeIcon: <Beenhere className="text-biens" />,
  },
  {
    labelKey: "settings_usage",
    to: "/dashboard/settings/usage",
    icon: <DataUsage />,
    activeIcon: <DataUsage className="text-biens" />,
  },
];

export const settingsHospitalMenuItems = [
  {
    labelKey: "settings_user",
    to: "/dashboard/settings",
    icon: <ManageAccounts />,
    activeIcon: <ManageAccounts className="text-biens" />,
  },
  {
    labelKey: "settings_subscription",
    to: "/dashboard/settings/subscription",
    icon: <Beenhere />,
    activeIcon: <Beenhere className="text-biens" />,
  },
  {
    labelKey: "settings_usage",
    to: "/dashboard/settings/usage",
    icon: <DataUsage />,
    activeIcon: <DataUsage className="text-biens" />,
  },
];
