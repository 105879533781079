import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ChatHeader from "../../components/Chat/ChatHeader";
import ChatBubble from "../../components/Chat/ChatBubble";
import ChatInput from "../../components/Chat/ChatInput";
import Button from "../../components/Button";
import CustomerInfo from "../../components/CustomerInfo";
import {
  REQUEST_GET_PREVIOUS_MESSAGES,
  REQUEST_PUT_MESSAGE_STATUS,
} from "../../api/requests";
import {
  setConversationRead,
  setPreviousMessage,
} from "../../store/slices/conversationsSlice";
import { CircularProgress } from "@mui/material";
import moment from "moment";

const Messages = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const { conversations } = useSelector(
    (state: RootState) => state.conversations,
  );
  const {
    company: { autoAssignBiens, userId },
  } = useSelector((state: RootState) => state.auth);
  const activeConversation = conversations.find(
    (item) => item._id === params.chatId,
  );
  const [isPreviousMessagesLoading, setIsPreviousMessagesLoading] =
    useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [firstMessage, setFirstMessage] = useState(new Date());
  const [lastMessageId, setLastMessageId] = useState("");

  const handleScroll = useCallback(() => {
    const container = messagesContainerRef.current;
    if (
      !container ||
      isPreviousMessagesLoading ||
      !hasMore ||
      !activeConversation
    ) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = container;

    if (scrollTop === clientHeight - scrollHeight) {
      setIsPreviousMessagesLoading(true);
      REQUEST_GET_PREVIOUS_MESSAGES({
        customerId: activeConversation._id,
        createdAt: firstMessage.getTime(),
      })
        .then(({ data }) => {
          if (!data.data.messages?.length) {
            setHasMore(false);
          } else {
            dispatch(
              setPreviousMessage({
                customerId: activeConversation._id,
                messages: data.data.messages,
              }),
            );
          }
        })
        .catch((err) => {
          console.log("error when fetching new messages", err);
        })
        .finally(() => {
          setIsPreviousMessagesLoading(false);
        });
    }
  }, [isPreviousMessagesLoading, firstMessage, hasMore, activeConversation]);

  useMemo(() => {
    if (activeConversation?.messages.length) {
      const sortMessagesOldToNew = [...activeConversation.messages].sort(
        (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(),
      );
      setFirstMessage(sortMessagesOldToNew[0].createdAt);
      setLastMessageId(
        sortMessagesOldToNew[sortMessagesOldToNew.length - 1]._id,
      );
      setHasMore(true);
    }
  }, [activeConversation?.messages]);

  useMemo(() => {
    if (!activeConversation) {
      return;
    }
    /**
     * Change message status to read
     */
    if (
      activeConversation.messages.find(
        (item) => item.role === "user" && item.status !== "read",
      )
    ) {
      dispatch(setConversationRead(activeConversation._id));
      REQUEST_PUT_MESSAGE_STATUS(activeConversation._id).catch((err) =>
        console.log("error happen when changing message status", err),
      );
    }
  }, [
    activeConversation?._id,
    activeConversation?.assignedAgentId,
    activeConversation?.messages,
  ]);

  useMemo(() => {
    if (lastMessageId) {
      setTimeout(() => {
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      }, 500);
    }
  }, [lastMessageId]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  if (!activeConversation) {
    return (
      <div className="h-full w-full flex flex-col justify-center items-center">
        Conversation couldnt found. <br />
        <span className="text-gray-600 my-5">
          Probably you are at wrong page. Click button to refresh messages
        </span>
        <Button
          title="Refresh"
          type="button"
          onClick={() =>
            (window.location.href = `${window.location.origin}/dashboard/messages`)
          }
        />
      </div>
    );
  }
  return (
    <div className="flex h-screen w-full">
      <div className="h-dvh lg:h-full w-full flex flex-col justify-between pt-[calc(50px)] lg:pt-0">
        <ChatHeader conversation={activeConversation} />
        <div
          className="h-full overflow-y-auto flex flex-col-reverse relative"
          ref={messagesContainerRef}
        >
          {activeConversation.messages.map((message) => (
            <ChatBubble key={message._id} message={message} />
          ))}
          {isPreviousMessagesLoading ? (
            <div className="flex min-h-[48px] max-h[48px] items-center justify-center">
              <CircularProgress
                className={`me-2 my-auto text-biens`}
                size={18}
              />
            </div>
          ) : null}
        </div>
        {activeConversation.source !== "phone" ? (
          <ChatInput
            customerId={activeConversation._id}
            assignedAgentId={activeConversation.assignedAgentId}
            isAIDisabled={
              !!activeConversation.assignedAgentId || !autoAssignBiens
            }
          />
        ) : null}
      </div>
      <CustomerInfo conversation={activeConversation} />
    </div>
  );
};

export default Messages;
