import { useTranslation } from "react-i18next";
import LineChart from "../../components/Charts/LineChart";
import moment from "moment";
import { useEffect, useState } from "react";
import { REQUEST_CALL_USAGE } from "../../api/requests";

type DATASET = {
  label: string;
  data: number[];
  fill: boolean;
  borderColor: string;
  tension: number;
};

type CHART_DATA = {
  labels: string[];
  datasets: DATASET[];
};

type RESPONSE_DATA = {
  month: number;
  totalCallMinutes: number;
  totalCalls: number;
};

const Usage = () => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<CHART_DATA>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    REQUEST_CALL_USAGE({ year: new Date().getFullYear() }).then((res) => {
      const data: RESPONSE_DATA[] = res.data.data;
      setChartData({
        labels: data.map((item) =>
          moment()
            .month(item.month - 1)
            .format("MMMM"),
        ),
        datasets: [
          {
            label: t("calls_per_min"),
            data: data.map((item) => item.totalCallMinutes),
            fill: false,
            borderColor: "#00A2E8",
            tension: 0.3,
          },
        ],
      });
    });
  }, []);

  return (
    <div>
      <LineChart title={t("usage_graph")} data={chartData} />
    </div>
  );
};

export default Usage;
