import React, { memo, useEffect, useState } from "react";
import { CircularProgress, ClickAwayListener } from "@mui/material";
import MainCard from "./MainCard";
import { Search, Check, Remove } from "@mui/icons-material";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { REQUEST_SEARCH_PRODUCTS } from "../../api/requests";
import useDebounce from "../../hooks/useDebounce";
import { Product } from "../../store/slices/conversationsSlice";
import { useDispatch } from "react-redux";

type Props = {
  handleSendMessage: (message: string, products?: Product[]) => void;
  handleCloseMenu: () => void;
};

type SEARCH_PRODUCT = {
  id: string;
  name: string;
  photoUrl: string;
  price: string;
  url: string;
  description: string;
};

const ProductSelectMenu: React.FC<Props> = ({
  handleSendMessage,
  handleCloseMenu,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [products, setProducts] = useState<SEARCH_PRODUCT[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { debouncedValue } = useDebounce(searchTerm, 500);

  useEffect(() => {
    setIsLoading(true);
    REQUEST_SEARCH_PRODUCTS({
      searchText: debouncedValue.trim() === "" ? "a" : debouncedValue.trim(),
    })
      .then(({ data }) => setProducts(data.data.products))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, [debouncedValue]);

  const handleSelectProduct = (productId: string) => {
    const isProductExists = selectedProducts.find(
      (item) => item.id === productId,
    );
    if (isProductExists) {
      setSelectedProducts(
        selectedProducts.filter((item) => item.id !== productId),
      );
    } else {
      const productData = products.find((item) => item.id === productId);
      if (!productData) {
        return;
      }
      const tmpSelectedProducts = [...selectedProducts];
      tmpSelectedProducts.push({
        id: productId,
        name: productData.name,
        url: productData.url,
        photoUrl: productData.photoUrl,
        price: productData.price,
      });
      setSelectedProducts(tmpSelectedProducts);
    }
  };

  return (
    <div className="absolute top-[-510px] left-0">
      <ClickAwayListener onClickAway={handleCloseMenu}>
        <div className="px-5">
          <MainCard elevation={8} content={false}>
            <div className="flex flex-col justify-between max-w-[400px] min-w-[400px] h-[500px] bg-white">
              <form action="">
                <div className="p-2 border-b-2 h-[55px]">
                  <div className="border-2 rounded-lg flex items-center py-1 px-2">
                    <Search className="text-gray-700" />
                    <input
                      type="text"
                      className="block w-full text-sm text-gray-700 outline-0 border-0 focus:outline-0 focus:border-0 mx-2 h-[24px]"
                      placeholder={t("type_a_product_name")}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      required
                      autoFocus
                    />
                  </div>
                </div>
              </form>
              {isLoading ? (
                <div className="h-full w-full flex items-center justify-center">
                  <CircularProgress
                    className={`me-2 my-auto`}
                    sx={{
                      color: "#00A2E8",
                    }}
                    size={18}
                  />
                </div>
              ) : !products.length ? (
                <div className="h-full w-full flex items-center justify-center">
                  <span className="text-gray-700 text-xs whitespace-break-spaces text-center">
                    {t("no_products")}
                  </span>
                </div>
              ) : (
                <div className="p-2 grid grid-cols-4 gap-2 h-full overflow-y-auto">
                  {products.map((product) => (
                    <div
                      key={product.id}
                      className="group relative"
                      onClick={() => handleSelectProduct(product.id)}
                    >
                      {selectedProducts.find(
                        (item) => item.id === product.id,
                      ) ? (
                        <div className="absolute top-[-6px] right-[-6px] w-6 h-6 bg-white rounded-full flex items-center justify-center shadow z-20">
                          <Check className="text-green-600" />
                        </div>
                      ) : null}

                      <div className="relative">
                        <img
                          src={product.photoUrl}
                          alt={product.name}
                          className="rounded w-full h-[130px]"
                        />
                        <div className="opacity-0 transition group-hover:transition group-hover:opacity-100 absolute top-0 left-0 h-full w-full bg-[rgba(0,0,0,0.1)] flex items-center justify-center cursor-pointer">
                          <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center">
                            {selectedProducts.find(
                              (item) => item.id === product.id,
                            ) ? (
                              <Remove className="text-red-600" />
                            ) : (
                              <Check className="text-green-600" />
                            )}
                          </div>
                        </div>
                      </div>
                      <h6 className="text-xs text-gray-700 my-1">
                        {product.name}
                      </h6>
                      <div className="text-xs text-gray-700">
                        {product.price}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className="p-2 border-t-2 flex items-center justify-end h-[55px]">
                <Button
                  title={t("send_n_products", {
                    count: selectedProducts.length,
                  })}
                  size="sm"
                  type="button"
                  // className="me-2"
                  disabled={!selectedProducts.length}
                  onClick={() => {
                    handleSendMessage("", selectedProducts);
                    handleCloseMenu();
                  }}
                />
                {/*

                <Button
                  title="Send fast purchase link"
                  size="sm"
                  type="button"
                  disabled={!selectedProducts.length}
                />
                   */}
              </div>
            </div>
          </MainCard>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default memo(ProductSelectMenu);
