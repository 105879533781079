const enTranslations = {
  login_header: "Login",
  dont_have_an_account: "Don't have an account?",
  register: "Register",
  email_input: "Email",
  email_input_placeholder: "email@gmail.com",
  password_input: "Password",
  password_input_placeholder: "••••••••",
  did_you_forgot_password: "Forgot your password?",
  reset_password: "Reset",
  reset_password_header: "Reset Your Password",
  back_page: "Go back",
  login_page: "login page",
  we_will_send_reset_link: "We will send a reset link to your email address.",
  send_button: "Send",
  thanks_for_interest: "Thank you for your interest.",
  thanks_for_interest_desc:
    "In order to best meet the needs of our customers and provide the most suitable solutions, we would like to conduct a preliminary meeting with you.\n\nBefore accessing our company's services, we value holding a demo presentation and a meeting to better understand your needs. This meeting will help us determine your needs and offer you the most suitable solution.\n\nTo create your demo request, please contact us using one of the contact information below.\n\n",
  request_demo: "Request a Demo",
  analytics: "Analytics",
  messages: "Messages",
  team: "Users",
  customers: "Customers",
  integrations: "Channels",
  chat_config: "Appearance",
  settings: "Settings",
  help: "Help",
  write_a_message: "Write a message",
  low_balance_warning: "Warning!",
  low_balance_warning_desc:
    "Your message balance is running low. You can purchase a message package for uninterrupted interactions with your customers.",
  message_package_button: "Message packages",
  all_messages: "All Messages",
  assigned_to_me: "My Inbox",
  is_active: "active",
  assign_to_me: "Take over the conversation",
  this_conversation_assigned: "This conversation is assigned to another agent",
  assign_to_agent: "Assign to agent",
  select_member: "Select an agent",
  customer_profile: "Customer Profile",
  first_view: "First Visit",
  first_call: "First Call",
  last_view: "Last Visit",
  last_call: "Last Call",
  total_visit: "Total Visits",
  total_call: "Total Calls",
  language: "Language",
  country: "Country",
  state: "City",
  timezone: "Timezone",
  platform: "Platform",
  browser: "Browser",
  email: "Email",
  phone: "Phone",
  add_member: "Add User",
  name: "Name",
  permissions: "Permissions",
  actions: "Actions",
  permission: "Permission",
  admin: "Admin",
  admin_desc:
    "Analytics, messages, team management, purchases, and all settings",
  executive: "Team Manager",
  executive_desc: "Analytics, messages, team management",
  agent: "Agent",
  agent_desc: "Messages",
  update_member: "Update Agent",
  member_delete_confirm: "Are you sure you want to delete the user {{name}}?",
  appearance_layout: "Main Colors",
  appearance_input: "Input Field",
  appearance_messages: "Messages",
  appearance_position: "Positioning",
  appearance_hbg: "Header background",
  appearance_cbg: "Chat background",
  appearance_astc: "Company name",
  appearance_ostc: "Status text",
  appearance_ibg: "Input field background",
  appearance_itc: "Text color",
  appearance_it: "Preview text",
  appearance_it_tooltip: "Text to be shown when the input field is empty",
  appearance_asic: "Send icon",
  appearance_rmbg: "Incoming message bubble",
  appearance_rmtc: "Incoming message text",
  appearance_rmdc: "Incoming message time",
  appearance_smbg: "Outgoing message bubble",
  appearance_smtc: "Outgoing message text",
  appearance_smdc: "Outgoing message time",
  appearance_dp: "Position",
  appearance_dp_l: "Left",
  appearance_dp_r: "Right",
  appearance_vm: "Height",
  appearance_hm: "Side distance",
  reset: "Reset",
  save: "Save",
  are_you_sure_reset: "Are you sure you want to reset?",
  not_be_revert: "This action cannot be undone",
  hard_reset: "Reset to default",
  reset_my_changes: "Reset my changes",
  chat_config_updated: "Chat appearance settings updated.",
  chat_config_update_error:
    "An error occurred while updating chat appearance settings.",
  user_image: "User Image",
  change_picture: "Change Picture",
  your_user_name:
    "Your name will be shown to visitors in the messages you send.",
  update: "Update",
  security: "Security",
  change_password: "Change Password",
  current_password: "Current Password",
  new_password: "New Password",
  confirm_new_password: "New Password (Repeat)",
  settings_user: "User Settings",
  settings_company: "Company Settings",
  settings_ai: "AI Settings",
  settings_subscription: "Purchases & Subscriptions",
  settings_usage: "Usage",
  chat_image: "Your Company Logo",
  company_name: "Your Company Name",
  company_name_desc: "This name will be shown to visitors in the chat screen.",
  welcome_message: "Welcome message",
  welcome_message_desc: "Welcome message to be sent to your visitors.",
  auto_assign: "Assign automatically to biens.ai",
  auto_assign_desc:
    "When this option is enabled, biens.ai will automatically respond to incoming messages.",
  ai_knowledge: "AI Instructions",
  ai_knowledge_desc:
    "You can add specific instructions for your business. Biens.ai will serve your customers based on these instructions.",
  add_rule: "Add Instruction",
  add_rule_placeholder:
    "Write an instruction... \nExample: \n-Use more emojis\n-Wish customers a Happy Ramadan",
  update_rule: "Update Instruction",
  add_ai_rule: "Add AI Instruction",
  edit_ai_rule: "Edit AI Instruction",
  upload_new_company_photo: "Upload new company logo",
  upload_new_user_photo: "Upload new profile photo",
  upload_photo: "Upload Photo",
  drop_photo_here: "Please drag and drop the photo here",
  or: "or",
  choose_from_files: "Choose from files",
  image_rules:
    "For a better experience, it is recommended to upload a photo in 1:1 format.",
  supported_formats: "Supported file formats are JPG, JPEG, PNG",
  web: "Web",
  web_desc:
    "Set up biens.ai chat on your website. Let biens.ai answer your visitors' needs!",
  whatsapp: "Whatsapp",
  whatsapp_desc:
    "Instantly respond to messages received on your Whatsapp line with biens.ai!",
  instagram: "Instagram",
  instagram_desc:
    "Instantly respond to messages received on your Instagram account with biens.ai!",
  messenger: "Messenger",
  messenger_desc:
    "Instantly respond to messages received on your Facebook page with biens.ai!",
  telegram: "Telegram",
  telegram_desc:
    "Instantly respond to messages received on your Telegram account with biens.ai!",
  cancel: "Cancel",
  confirm: "Confirm",
  confirm_delete_ai_rule: "Are you sure you want to delete the AI instruction?",
  type_a_product_name: "Type a product name",
  send_n_products: "Send ({{count}}) products",
  no_products: "No products found.\nPlease try changing the search content.",
  go_to_product: "Go to product",
  fast_replies: "Quick Replies",
  fast_replies_desc:
    "You can use the quick replies you define here in the messages screen. Defined quick replies are user-specific. Quick messages you define for your profile cannot be viewed or used by other members of your team.",
  add_fast_reply: "Add Quick Reply",
  create_fast_reply: "Create Quick Reply",
  edit_fast_reply: "Edit Quick Reply",
  create: "Create",
  add_fast_reply_title_placeholder: "Write a quick reply title",
  add_fast_reply_placeholder: "Write a quick reply.",
  confirm_delete_fast_reply: "Are you sure you want to delete the quick reply?",
  fast_reply_delete_success: "Quick reply deleted successfully",
  you_have_not_fast_reply: "You do not have any quick replies defined yet",
  you_have_not_fast_reply_chat_screen:
    "You do not have any quick replies defined yet. You can define quick replies from Settings > Quick Replies menu.",
  search_fast_reply_placeholder: "Search by quick reply title or content",
  fast_send_description:
    "You can use the <strong>{{helpText}}</strong> keys to send a message.",
  fast_reply_empty_search_result:
    "No quick replies found matching your search criteria.",
  no_data: "No data found",
  calls: "Calls",
  contacts: "Contacts",
  today: "Today",
  help_text:
    "For your support requests, you can reach us at the email address below. Our team will get back to you as soon as possible.",
  usage_graph: "Usage Graph",
  calls_per_min: "Calls (min)",
  call_count: "Call Count",
  call_duration: "Call Duration",
  mins: "min.",
  number_of_appointments: "Number of Appointments Taken",
  interest_of_departments: "Department Interest",
  appointment_of_departments: "Department Appointments",
  created_appointment: "Appointment Created",
  no_of_interest: "Appointment Inquiry",
  appointment: "Appointment",
  contact: "Contact",
  channels: "Communication Channels",
  no_of_visit: "Call Count",
  search_contacts: "Search contacts by name, phone number, or email address",
};

export default enTranslations;
