import { Link, NavLink, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  SettingsOutlined,
  Settings,
  SupportOutlined,
  Support,
  Logout,
  Menu,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  setCompanyData,
  setSocketStatus,
} from "../store/slices/authSlice";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useRef, useState } from "react";
import {
  REQUEST_CUSTOMERS,
  REQUEST_DASHBOARD_COMPANY,
  REQUEST_GET_CONVERSATION_BY_ID,
  REQUEST_GET_MESSAGES,
  REQUEST_GET_TEAM_MEMBERS,
} from "../api/requests";
import { RootState, store } from "../store/store";
import {
  addNewWebCustomer,
  addSocketMessage,
  addWaitList,
  assignToAssistant,
  initialSetConversations,
  removeWaitList,
  saveNewCall,
} from "../store/slices/conversationsSlice";
import { setCustomers } from "../store/slices/customersSlice";
import { setTeam } from "../store/slices/teamSlice";
import ProfilePhoto from "../components/ProfilePhoto";
import { eCommerceMenuItems, hospitalMenuItems } from "../utils/menuItems";

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { company, token, isSocketOn } = useSelector(
    (state: RootState) => state.auth,
  );
  const asideRef = useRef<HTMLElement>(null);
  const [socket, setSocket] = useState<WebSocket>();

  const DashboardGroups = (
    company.companyType === "eCommerce" ? eCommerceMenuItems : hospitalMenuItems
  ).map((item) => ({ ...item, title: t(item.titleKey) }));
  const DashboardBottomGroups = [
    {
      title: t("settings"),
      icon: <SettingsOutlined />,
      activeIcon: <Settings className="text-biens" />,
      link: "/dashboard/settings",
    },
    {
      title: t("help"),
      icon: <SupportOutlined />,
      activeIcon: <Support className="text-biens" />,
      link: "/dashboard/help",
    },
  ];

  const connectSocket = () => {
    const websocket = new WebSocket(
      "wss://lr5hpvml0j.execute-api.us-east-1.amazonaws.com/production/",
    );
    websocket.onopen = () => {
      dispatch(setSocketStatus(true));
      websocket.send(
        JSON.stringify({
          action: "panelConnect",
          token: `Bearer ${token}`,
        }),
      );
    };
    websocket.onmessage = (message) => {
      try {
        const data = JSON.parse(message.data);
        if (data.action === "newMessage") {
          const storeConversations =
            store.getState().conversations.conversations;
          const waitList = store.getState().conversations.waitList;
          const foundConversation = storeConversations.find(
            (item) => item._id === data.message.customerId,
          );
          if (foundConversation) {
            dispatch(addSocketMessage(data.message));
          } else if (data.message.customerId) {
            const checkWaitlist = waitList.find(
              (item) => item === data.message.customerId,
            );
            if (!checkWaitlist) {
              dispatch(addWaitList(data.message.customerId));
              setTimeout(() => {
                REQUEST_GET_CONVERSATION_BY_ID(data.message.customerId)
                  .then((res) => {
                    dispatch(addNewWebCustomer(res.data.data.conversation));
                    dispatch(removeWaitList(data.message.customerId));
                  })
                  .catch((err) =>
                    console.log("error fetching conversation", err),
                  );
              }, 12000);
            }
          }
        } else if (data.action === "newAssign") {
          dispatch(
            assignToAssistant({
              customerId: data.customer.customerId,
              agentId: data.customer.agentId,
            }),
          );
        } else if (data.action === "newCall") {
          dispatch(saveNewCall(data.conversation));
        }
      } catch (e) {}
    };
    websocket.onclose = () => {
      dispatch(setSocketStatus(false));
      setTimeout(() => {
        connectSocket();
      }, 1000);
    };
    setSocket(websocket);
  };

  useEffect(() => {
    if (!socket) {
      connectSocket();
    }

    const promises = [
      REQUEST_DASHBOARD_COMPANY(),
      REQUEST_GET_MESSAGES({ page: 0 }),
      REQUEST_CUSTOMERS({}),
      REQUEST_GET_TEAM_MEMBERS(),
    ];

    Promise.all(promises)
      .then((responses) => {
        const companyData = responses[0].data.data.company;
        const messagesData = responses[1].data.data.messages;
        const customersData = responses[2].data.data.customers;
        const teamMembers = responses[3].data.data.teamMembers;
        dispatch(setCompanyData(companyData));
        dispatch(setCustomers(customersData));
        dispatch(setTeam(teamMembers));
        REQUEST_GET_MESSAGES({
          page: 0,
          assignedToMe: true,
          isAutoAI: companyData.autoAssignBiens,
        })
          .then(({ data }) => {
            const assignedConversations = data.data.messages;
            dispatch(
              initialSetConversations({
                allConversation: messagesData,
                assignedConversations: assignedConversations,
              }),
            );
          })
          .catch((err) =>
            console.log(err, "error happen when fetching assigned messages"),
          );
      })
      .catch(() => console.log("error happen when getting dashboard"));
  }, []);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleToggleMobileMenu = () => {
    const isClosed = asideRef.current?.classList.contains("-translate-x-full");
    if (isClosed) {
      asideRef.current?.classList.remove("-translate-x-full");
    } else {
      asideRef.current?.classList.add("-translate-x-full");
    }
  };

  return (
    <div className="flex w-screen h-dvh lg:h-screen">
      <aside
        className="w-screen lg:w-72 min-w-72 h-screen transition-transform fixed lg:relative left-0 top-0 -translate-x-full lg:translate-x-0 flex flex-col justify-between bg-gray-100 overflow-y-auto px-3 py-4 z-40"
        aria-label="Sidebar"
        ref={asideRef}
      >
        <div>
          <ul className="font-medium">
            <Link to={"https://biens.ai"}>
              <h1 className="font-bold text-biens text-2xl mb-2 font-biens">
                biens.ai
              </h1>
            </Link>
            {DashboardGroups.map((item) => (
              <NavLink
                to={item.link}
                key={item.link}
                className={({ isActive }) =>
                  isActive
                    ? "text-gray-700 p-2 rounded mb-1 flex items-center text-biens bg-gray-200"
                    : "text-gray-700 p-2 rounded hover:bg-gray-200 mb-1 flex items-center transition hover:transition"
                }
                onClick={handleToggleMobileMenu}
              >
                {({ isActive }) => (
                  <>
                    {isActive ? item.activeIcon : item.icon}
                    <span
                      className={`ms-2 whitespace-nowrap text-sm font-bold ${isActive ? "text-biens" : ""}`}
                    >
                      {item.title}
                    </span>
                  </>
                )}
              </NavLink>
            ))}
          </ul>
        </div>
        <div>
          <ul className="font-medium">
            {DashboardBottomGroups.map((item) => (
              <NavLink
                to={item.link}
                key={item.link}
                className={({ isActive }) =>
                  isActive
                    ? "text-gray-700 p-2 rounded mb-1 flex items-center text-biens bg-gray-200"
                    : "text-gray-700 p-2 rounded hover:bg-gray-200 mb-1 flex items-center transition hover:transition"
                }
                onClick={handleToggleMobileMenu}
              >
                {({ isActive }) => (
                  <>
                    {isActive ? item.activeIcon : item.icon}
                    <span
                      className={`ms-2 whitespace-nowrap text-sm font-bold ${isActive ? "text-biens" : ""}`}
                    >
                      {item.title}
                    </span>
                  </>
                )}
              </NavLink>
            ))}
            <hr />
            <NavLink
              to={"/dashboard/settings/usage"}
              className={
                "text-gray-700 mt-2 p-2 rounded hover:bg-gray-200 mb-1 flex flex-col transition hover:transition"
              }
              onClick={handleToggleMobileMenu}
            >
              <span className={`text-xs font-bold mb-2`}>
                Kalan Kullanımlarım
              </span>
              <div className="flex items-center justify-between">
                <span className={`text-xs font-bold`}>Kalan Dakika</span>
                <span className={`text-xs font-bold text-biens`}>
                  {`${company.callBalance} ${t("mins")}`}
                </span>
              </div>
            </NavLink>
            <div className="flex items-center justify-between">
              <NavLink
                to={"/dashboard/settings"}
                className={
                  "relative mt-2 text-gray-700 p-2 w-full rounded hover:bg-gray-200 mb-1 flex items-center transition hover:transition"
                }
                onClick={handleToggleMobileMenu}
              >
                <>
                  <ProfilePhoto
                    name={company.userName}
                    photoUrl={company.userPhotoUrl}
                    size={30}
                  />
                  <span className={`ms-2 whitespace-nowrap text-sm font-bold`}>
                    {company.userName}
                  </span>
                  <div className="absolute left-1 top-1">
                    <span className="relative flex h-3 w-3">
                      <span
                        className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${isSocketOn ? "bg-green-600" : "bg-red-600"}`}
                      ></span>
                      <span
                        className={`relative inline-flex rounded-full h-3 w-3 ${isSocketOn ? "bg-green-500" : "bg-red-500"}`}
                      ></span>
                    </span>
                  </div>
                </>
              </NavLink>
              <button
                onClick={handleLogout}
                className="rounded hover:bg-red-200 transition hover:transition p-2"
              >
                <Logout className="text-red-600" />
              </button>
            </div>
          </ul>
        </div>
      </aside>
      <div className="w-full">
        <div className="px-3 h-[50px] bg-white border-b-2 lg:hidden fixed top-0 w-full z-50 flex items-center justify-between">
          <div className="px-2 flex items-center justify-between w-full">
            <h1 className="font-bold text-biens text-xl font-biens">
              biens.ai
            </h1>
            <button onClick={handleToggleMobileMenu}>
              <Menu className="text-gray-700" />
            </button>
          </div>
        </div>
        <div className="bg-gray-50 lg:h-screen w-full overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
