import ContactsTable from "../../components/ContactsTable";

const Contacts = () => {
  return (
    <div className="lg:container lg:mx-auto h-full">
      <div className="w-full h-full min-h-screen px-3 py-4 pt-[calc(50px+1rem)] lg:pt-4">
        <ContactsTable />
      </div>
    </div>
  );
};

export default Contacts;
