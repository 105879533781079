import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Conversation } from "./conversationsSlice";

export type Customer = {
  _id: string;
  name: string;
  lastView: Date;
  visitCount: number;
  photoKey?: string;
  photoUrl?: string;
  email?: string;
  phoneNumber?: null;
  createdAt: Date;
  sources: Conversation["source"][];
};

interface CustomersState {
  customers: Customer[];
  isLoading: boolean;
}

const initialState: CustomersState = {
  customers: [],
  isLoading: true,
};

export const customersSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    setCustomers: (state, action: PayloadAction<Customer[]>) => {
      state.customers = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setCustomers } = customersSlice.actions;

export default customersSlice.reducer;
