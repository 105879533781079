import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Help = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:container lg:mx-auto px-3 py-4 pt-[calc(50px+1rem)] min-h-dvh lg:min-h-0 lg:pt-4">
      {t("help_text")}
      <br />
      <Link to="mailto:destek@biens.ai" className="text-blue-500 underline">
        destek@biens.ai
      </Link>
    </div>
  );
};

export default Help;
