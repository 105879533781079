import MessageBalanceWarning from "../../components/MessageBalanceWarning";
import DropdownSelect from "../../components/DropdownSelect";
import { ChartPastOptions } from "../../components/Charts/constants";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ECommerceAnalytics from "./eCommerce";
import HospitalAnalytics from "./hospital";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const Analytics = () => {
  const { t } = useTranslation();
  const {
    company: { companyType },
  } = useSelector((state: RootState) => state.auth);
  const [selectedDateRange, setSelectedDateRange] = useState("7");

  return (
    <div className="lg:container lg:mx-auto px-3 py-4 pt-[calc(50px+1rem)] lg:pt-4">
      {companyType !== "hospital" ? <MessageBalanceWarning /> : null}
      <div className="flex w-full items-center justify-between mb-3">
        <h1 className="font-bold text-xl text-gray-700">{t("analytics")}</h1>
        <DropdownSelect
          label={"Last"}
          options={ChartPastOptions}
          selectedValue={selectedDateRange}
          handleChangeValue={(newVal) => setSelectedDateRange(newVal)}
        />
      </div>
      {companyType === "hospital" ? (
        <HospitalAnalytics selectedDateRange={Number(selectedDateRange)} />
      ) : (
        <ECommerceAnalytics selectedDateRange={Number(selectedDateRange)} />
      )}
    </div>
  );
};

export default Analytics;
