import MiniChart from "../../components/Charts/MiniChart";
import React, { useEffect, useState } from "react";
import BarChart from "../../components/Charts/BarChart";
import { BAR_CHART_DATA, MINI_CHART_DATA } from "./types";
import { REQUEST_GET_ANALYTICS } from "../../api/requests";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const emptyChartData = [
  { value: 0, title: "" },
  { value: 0, title: "" },
  { value: 0, title: "" },
  { value: 0, title: "" },
  { value: 0, title: "" },
  { value: 0, title: "" },
  { value: 0, title: "" },
];

const ECommerceAnalytics = ({
  selectedDateRange,
}: {
  selectedDateRange: number;
}) => {
  const { team } = useSelector((state: RootState) => state.team);
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState<BAR_CHART_DATA>();
  const [sources, setSources] = useState<BAR_CHART_DATA>();
  const [products, setProducts] = useState<BAR_CHART_DATA>();
  const [wordCounts, setWordCounts] = useState<BAR_CHART_DATA>();
  const [allCustomers, setAllCustomers] =
    useState<MINI_CHART_DATA>(emptyChartData);
  const [userMessages, setUserMessages] =
    useState<MINI_CHART_DATA>(emptyChartData);
  const [assistantMessages, setAssistantMessages] =
    useState<MINI_CHART_DATA>(emptyChartData);

  useEffect(() => {
    if (!team.length) {
      return;
    }
    setIsLoading(true);
    REQUEST_GET_ANALYTICS({ days: selectedDateRange })
      .then(({ data }) => {
        if (data.data.sources) {
          const newSourceData = {
            labels: data.data.sources.map((item: any) => item.label),
            datasets: [
              {
                label: "Platform",
                data: data.data.sources.map((item: any) => item.value),
                backgroundColor: "#00A2E8",
                barThickness: 18,
                borderRadius: 12,
              },
            ],
          };
          setSources(newSourceData);
        }
        if (data.data.cities) {
          const newLocationsData = {
            labels: data.data.cities.map((item: any) => item.label),
            datasets: [
              {
                label: "Şehir",
                data: data.data.cities.map((item: any) => item.value),
                backgroundColor: "#00A2E8",
                barThickness: 18,
                borderRadius: 12,
              },
            ],
          };
          setLocations(newLocationsData);
        }
        if (data.data.productCounts) {
          const newProductsData = {
            labels: data.data.productCounts.map(
              (item: any) => item.productName,
            ),
            datasets: [
              {
                label: "Önerme",
                data: data.data.productCounts.map((item: any) => item.value),
                backgroundColor: "#00A2E8",
                barThickness: 18,
                borderRadius: 12,
              },
            ],
          };
          setProducts(newProductsData);
        }
        if (data.data.wordCounts) {
          const newWordCountsData = {
            labels: data.data.wordCounts.map((item: any) => {
              if (item.assistantId === "biens") {
                return "biens.ai";
              }
              const foundTeamMember = team.find(
                (t) => t._id === item.assistantId,
              );
              return foundTeamMember?.name || "";
            }),
            datasets: [
              {
                label: "Kelime Sayısı",
                data: data.data.wordCounts.map((item: any) => item.value),
                backgroundColor: "#00A2E8",
                barThickness: 18,
                borderRadius: 12,
              },
            ],
          };
          setWordCounts(newWordCountsData);
        }

        if (data.data.allMessages?.length) {
          setAllCustomers(
            data.data.allMessages.map((item: any) => ({
              title: moment(new Date(Number(item.date))).format("DD.MM.YYYY"),
              value: item.total,
            })),
          );
        }

        if (data.data.userMessages?.length) {
          setUserMessages(
            data.data.userMessages.map((item: any) => ({
              title: moment(new Date(Number(item.date))).format("DD.MM.YYYY"),
              value: item.total,
            })),
          );
        }

        if (data.data.assistantMessages?.length) {
          setAssistantMessages(
            data.data.assistantMessages.map((item: any) => ({
              title: moment(new Date(Number(item.date))).format("DD.MM.YYYY"),
              value: item.total,
            })),
          );
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [selectedDateRange]);
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:mb-5">
        <MiniChart
          title="Sesli İletişim Kurulan Müşteri"
          chartData={emptyChartData}
          isLoading={isLoading}
        />
        <MiniChart
          title="Yazılı İletişim Kurulan Müşteri"
          chartData={allCustomers}
          isLoading={isLoading}
        />
        <MiniChart
          title="Toplam Gelen Mesaj"
          chartData={userMessages}
          isLoading={isLoading}
        />
        <MiniChart
          title="Toplam Gönderilen Mesaj"
          chartData={assistantMessages}
          isLoading={isLoading}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 my-5">
        <BarChart
          title={"Kelime üretim performansı"}
          data={wordCounts}
          direction="x"
          isLoading={isLoading}
        />
        <BarChart
          title={"Ürün önerisi"}
          data={products}
          direction="x"
          isLoading={isLoading}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-5">
        <BarChart
          title={"Ziyaretçi Lokasyonları"}
          data={locations}
          direction="y"
          isLoading={isLoading}
        />
        <BarChart
          title={"Ziyaretçi Platformları"}
          data={sources}
          direction="y"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default ECommerceAnalytics;
