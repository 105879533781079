import moment from "moment";
import {
  Facebook,
  Instagram,
  Language,
  Phone,
  Telegram,
  TuneOutlined,
  WhatsApp,
} from "@mui/icons-material";
import ProfilePhoto from "../ProfilePhoto";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import React, { useEffect, useState } from "react";
import { Customer } from "../../store/slices/customersSlice";
import { REQUEST_CUSTOMERS } from "../../api/requests";
import useDebounce from "../../hooks/useDebounce";
import ContactInfo from "./ContactInfo";
import { useTranslation } from "react-i18next";

const CustomerTable = () => {
  const { customers, isLoading } = useSelector(
    (state: RootState) => state.customers,
  );
  const { t } = useTranslation();
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
  const [searchText, setSearchText] = useState("");
  const { debouncedValue } = useDebounce(searchText, 500);
  const [infoModalVisible, setInfoModalVisible] = useState({
    status: false,
    customerId: "",
  });

  useEffect(() => {
    if (!debouncedValue.trim()) {
      setFilteredCustomers([...customers]);
      return;
    }

    REQUEST_CUSTOMERS({ search: debouncedValue })
      .then(({ data }) => {
        setFilteredCustomers(data.data.customers);
      })
      .catch((err) => console.log(err));
  }, [customers, debouncedValue]);

  return (
    <div className="w-full h-full shadow rounded-lg bg-white">
      <div className="p-2 flex items-center bg-white rounded-t-lg">
        <form className="flex-1">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only"
          >
            Search
          </label>
          <div className="flex items-center border border-gray-200 rounded-lg bg-gray-50 py-3 px-2">
            <div className="flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="w-full ps-3 text-sm outline-0 bg-gray-50 text-gray-900 focus:ring-blue-500 focus:border-blue-500"
              placeholder={t("search_contacts")}
              required
            />
          </div>
        </form>
      </div>

      <ContactInfo
        isVisible={infoModalVisible.status}
        customerId={infoModalVisible.customerId}
        handleClose={() =>
          setInfoModalVisible({
            status: false,
            customerId: "",
          })
        }
      />

      <div className="p-2 overflow-x-auto">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  {t("contact")}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t("email")}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t("phone")}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t("channels")}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t("no_of_visit")}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t("last_call")}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomers.map((customer) => (
                <tr
                  key={customer._id}
                  className="bg-white border-b border-gray-200 hover:bg-gray-50"
                  onClick={() =>
                    setInfoModalVisible({
                      status: true,
                      customerId: customer._id,
                    })
                  }
                >
                  <th scope="row" className="px-6 py-4 flex items-center">
                    <ProfilePhoto
                      name={customer.name}
                      photoUrl={undefined}
                      size={36}
                    />
                    <span className="font-medium text-gray-900 whitespace-nowrap">
                      {customer.name}
                    </span>
                  </th>
                  <td className="px-6 py-4">{customer.email || "-"}</td>
                  <td className="px-6 py-4">{customer.phoneNumber || "-"}</td>
                  <td className="px-6 py-4">
                    {customer.sources.map((source, i) => (
                      <div key={source + i}>
                        {source === "web" ? (
                          <Language sx={{ fontSize: 18, color: "#00a2e8" }} />
                        ) : source === "whatsapp" ? (
                          <WhatsApp sx={{ fontSize: 18, color: "#25D366" }} />
                        ) : source === "messenger" ? (
                          <Facebook sx={{ fontSize: 18, color: "#1877F2" }} />
                        ) : source === "instagram" ? (
                          <Instagram sx={{ fontSize: 18, color: "#DD2A7B" }} />
                        ) : source === "telegram" ? (
                          <Telegram sx={{ fontSize: 18, color: "#24A1DE" }} />
                        ) : source === "phone" ? (
                          <Phone sx={{ fontSize: 18, color: "#00A2E8" }} />
                        ) : null}
                      </div>
                    ))}
                  </td>
                  <td className="px-6 py-4">{customer.visitCount}</td>
                  <td className="px-6 py-4">
                    {moment(customer.lastView).fromNow()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomerTable;
