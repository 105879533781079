import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { ChartData } from "chart.js";
import DropdownSelect from "../../DropdownSelect";
import { ChartPastOptions } from "../constants";

type Props = {
  title: string;
  data: ChartData<"line">;
};
const LineChart: React.FC<Props> = ({ title, data }) => {
  return (
    <div className="bg-white rounded-lg shadow p-4">
      <div className="mb-2">
        <span>{title}</span>
      </div>
      <Line
        data={data}
        options={{
          indexAxis: "x",
          elements: {
            bar: {
              borderWidth: 0,
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (context: any) {
                  var label = context.dataset.label || "";

                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y;
                  }
                  return label;
                },
              },
              backgroundColor: "#edf3ff",
              borderColor: "grey",
              boxHeight: 10,
              borderWidth: 0.2,
              titleFont: {
                size: 12,
              },
              titleColor: "#2c73ff",
              bodyColor: "#2c73ff",
              bodyFont: {
                size: 12,
              },
              displayColors: false,
              caretSize: 2,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              ticks: {
                display: true,
              },
              max: 2700,
              min: 1500,
              grid: {
                display: false,
              },
            },
            x: {
              ticks: {
                display: true,
                callback: function (value, index) {
                  if (data?.labels) {
                    const labelString = data.labels[index] as string;
                    return labelString.length > 8
                      ? `${labelString.slice(0, 8).trim()}...`
                      : labelString;
                  }
                  return "";
                },
              },
              grid: {
                display: false,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default LineChart;
