import React, { useCallback, useEffect, useRef, useState } from "react";
import ConversationItem from "../components/ConversationItem";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { CircularProgress } from "@mui/material";
import { REQUEST_GET_MESSAGES } from "../api/requests";
import { setConversations } from "../store/slices/conversationsSlice";
import { useTranslation } from "react-i18next";
import {
  Facebook,
  Instagram,
  Language,
  Telegram,
  WhatsApp,
} from "@mui/icons-material";
import { Company } from "../store/slices/authSlice";

type CHANNEL_KEYS =
  | "isWeb"
  | "isWhatsapp"
  | "isInstagram"
  | "isMessenger"
  | "isTelegram";
const CHANNELS: { icon: JSX.Element; name: string; key: CHANNEL_KEYS }[] = [
  {
    icon: <Language sx={{ fontSize: 18, color: "#00a2e8" }} />,
    name: "Web",
    key: "isWeb",
  },
  {
    icon: <WhatsApp sx={{ fontSize: 18, color: "#25D366" }} />,
    name: "Whatsapp",
    key: "isWhatsapp",
  },
  {
    icon: <Instagram sx={{ fontSize: 18, color: "#DD2A7B" }} />,
    name: "Instagram",
    key: "isInstagram",
  },
  {
    icon: <Facebook sx={{ fontSize: 18, color: "#1877F2" }} />,
    name: "Messenger",
    key: "isMessenger",
  },
  {
    icon: <Telegram sx={{ fontSize: 18, color: "#24A1DE" }} />,
    name: "telegram",
    key: "isTelegram",
  },
];

const MessagesLayout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const navigation = useNavigate();
  const { conversations } = useSelector(
    (state: RootState) => state.conversations,
  );
  const {
    company: { userId, autoAssignBiens, companyType },
  } = useSelector((state: RootState) => state.auth);
  const [activeMenu, setActiveMenu] = useState<"all" | "assigned">(
    autoAssignBiens ? "all" : "assigned",
  );
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedChannel, setSelectedChannel] = useState<CHANNEL_KEYS>();

  useEffect(() => {
    if (!params.chatId && conversations.length) {
      navigation(`/dashboard/messages/${conversations[0]._id}`);
    }
  }, [params.chatId]);

  const activeTab = "text-biens border-b-biens ";

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container || isNewPageLoading || !hasMore) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = container;
    if (scrollTop + clientHeight >= scrollHeight) {
      setIsNewPageLoading(true);

      REQUEST_GET_MESSAGES({
        page: page + 1,
        assignedToMe: activeMenu === "assigned",
        isAutoAI: autoAssignBiens,
      })
        .then(({ data }) => {
          if (!data.data.messages?.length) {
            setHasMore(false);
          } else {
            dispatch(setConversations(data.data.messages));
          }
          setPage(page + 1);
        })
        .catch((err) => {
          console.log("error when fetching new messages", err);
        })
        .finally(() => {
          setIsNewPageLoading(false);
        });
    }
  }, [activeMenu, isNewPageLoading, hasMore]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  const selectedConversations =
    activeMenu === "all"
      ? conversations
      : conversations.filter((item) => item.assignedAgentId === userId);

  const isThereUnreadMessage = !!conversations.find((item) =>
    item.messages.find(
      (message) => message.role === "user" && message.status === "sent",
    ),
  );

  return (
    <div className="h-full w-full">
      <div className="flex w-full h-full">
        <div
          id="conversation-menu"
          className="w-screen lg:w-[25%] lg:min-w-[300px] h-dvh lg:h-full pt-[50px] lg:pt-0 fixed top-0 left-0 lg:relative transition-transform lg:translate-x-0 overflow-y-auto bg-[#fcfcfc] lg:border-r-2"
          ref={containerRef}
        >
          {companyType === "eCommerce" ? (
            <>
              <div className="flex border-b-2 h-[60px] items-center">
                <ul className="flex text-sm font-medium text-center text-gray-700 w-full">
                  <li className="w-full">
                    <button
                      onClick={() => setActiveMenu("all")}
                      className={`relative inline-flex items-center justify-center p-4 pb-2 h-[60px] w-full border-b-2 group ${activeMenu === "all" ? activeTab : "border-transparent hover:text-gray-600 hover:border-gray-300"}`}
                    >
                      {isThereUnreadMessage ? (
                        <div className="w-2 h-2 bg-blue-400 flex items-center justify-center absolute top-3 right-3 rounded-full text-white text-xs" />
                      ) : null}
                      {t("all_messages")}
                    </button>
                  </li>
                  <li className="w-full">
                    <button
                      onClick={() => setActiveMenu("assigned")}
                      className={`relative inline-flex items-center justify-center p-4 pb-2 h-[60px] w-full border-b-2 group ${activeMenu === "assigned" ? activeTab : "border-transparent hover:text-gray-600 hover:border-gray-300"}`}
                    >
                      {isThereUnreadMessage ? (
                        <div className="w-2 h-2 bg-blue-400 flex items-center justify-center absolute top-3 right-3 rounded-full text-white text-xs" />
                      ) : null}
                      {t("assigned_to_me")}
                    </button>
                  </li>
                </ul>
              </div>
              <div className="w-full flex flex-row justify-between">
                {CHANNELS.map((item) => (
                  <button
                    key={item.key}
                    className={`flex flex-1 items-center justify-center transition hover:transition hover:bg-gray-100 p-1 py-2 ${selectedChannel === item.key ? "bg-gray-200" : ""}`}
                    onClick={() => {
                      setSelectedChannel(
                        item.key === selectedChannel ? undefined : item.key,
                      );
                    }}
                  >
                    {item.icon}
                  </button>
                ))}
              </div>
            </>
          ) : null}
          {selectedConversations.map((item) => (
            <ConversationItem conversation={item} key={item._id} />
          ))}
          {isNewPageLoading ? (
            <div className="flex border-b-2 h-[60px] items-center justify-center">
              <CircularProgress
                className={`me-2 my-auto text-biens`}
                size={18}
              />
            </div>
          ) : null}
        </div>
        <div className="bg-gray-100 w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MessagesLayout;
