import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import React, { useState } from "react";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { MINI_CHART_DATA } from "../../../pages/Analytics/types";
Chart.register(CategoryScale);

const getGradient = (ctx: any) => {
  let gradient = ctx.createLinearGradient(0, 0, 0, 160);
  gradient.addColorStop(0, "rgba(44, 115, 255, 0.4)");
  gradient.addColorStop(0.5, "rgba(44, 115, 255, 0.3)");
  gradient.addColorStop(1, "rgba(44, 115, 255, 0)");
  return gradient;
};

const options = {
  responsive: true,
  fill: true,
  borderColor: "#00A2E8",
  borderWidth: 2,
  height: 100,
  backgroundColor: function (context: any) {
    const chart = context.chart;
    const { ctx, chartArea } = chart;

    if (!chartArea) {
      // This case happens on initial chart load
      return null;
    }
    return getGradient(ctx);
  },
  maintainAspectRatio: false,
  bezierCurve: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  interaction: {
    intersect: false,
  },
  elements: {
    point: {
      pointStyle: "circle",
      radius: 2,
    },
  },
  scales: {
    y: {
      max: 6,
      stacked: true,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    x: {
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
};

type Props = {
  title: string;
  chartData: MINI_CHART_DATA;
  isLoading: boolean;
  tooltipExtraText?: string;
};
const MiniChart: React.FC<Props> = ({
  title,
  chartData,
  isLoading,
  tooltipExtraText,
}) => {
  const data = {
    labels: chartData.map((item) => item.title),
    datasets: [
      {
        data: chartData.map((item) => item.value),
        tension: 0.4,
      },
    ],
  };

  return (
    <div className="h-[220px] w-full flex flex-col items-center justify-between rounded-lg shadow p-2 bg-white relative">
      <div className="h-full w-full text-gray-700 px-2 flex justify-between items-center">
        <div className="font-medium">{title}</div>
        <div className="flex items-center">
          <div className="bg-biens py-1 px-3 rounded-lg text-white font-bold">
            {`${chartData.reduce((a, b) => a + b.value, 0).toLocaleString("tr-TR")}${tooltipExtraText ? ` ${tooltipExtraText}` : ""}`}
          </div>
        </div>
      </div>
      <div className="h-[160px] w-full">
        {isLoading ? (
          <div className="flex items-center justify-center h-[160px]">
            <CircularProgress className={`me-2 my-auto text-biens`} size={18} />
          </div>
        ) : (
          <Line
            data={data}
            options={{
              ...options,
              plugins: {
                ...options.plugins,
                tooltip: {
                  callbacks: {
                    title: function (context: any) {
                      var title = context?.[0]?.label || "";
                      return title;
                    },
                    label: function (context: any) {
                      var label = context.dataset.label || "";

                      if (label) {
                        label += ": ";
                      }
                      if (context.parsed.y !== null) {
                        label += context.parsed.y;
                      }
                      return `${label}${tooltipExtraText ? ` ${tooltipExtraText}` : ""}`;
                    },
                  },
                  backgroundColor: "#edf3ff",
                  borderColor: "grey",
                  boxHeight: 10,
                  borderWidth: 0.2,
                  titleFont: {
                    size: 12,
                  },
                  titleColor: "#2c73ff",
                  bodyColor: "#2c73ff",
                  bodyFont: {
                    size: 12,
                  },
                  displayColors: false,
                  caretSize: 2,
                },
              },
              scales: {
                ...options.scales,
                y: {
                  ...options.scales.y,
                  max: chartData.length
                    ? [...chartData].sort((a, b) => b.value - a.value)[0]
                        .value *
                        1.1 ===
                      0
                      ? 1
                      : [...chartData].sort((a, b) => b.value - a.value)[0]
                          .value * 1.1
                    : 0.5,
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MiniChart;
