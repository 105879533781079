import MiniChart from "../../components/Charts/MiniChart";
import React, { useEffect, useState } from "react";
import BarChart from "../../components/Charts/BarChart";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { REQUEST_CALL_ANALYTICS } from "../../api/requests";

type CALL_ANALYTICS = {
  date: string;
  totalCallSeconds: number;
  totalCalls: number;
};

type CHART_DATA = {
  title: string;
  value: number;
};

const HospitalAnalytics = ({
  selectedDateRange,
}: {
  selectedDateRange: number;
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [callDurations, setCallDurations] = useState<CHART_DATA[]>([]);
  const [callCounts, setCallCounts] = useState<CHART_DATA[]>([]);

  useEffect(() => {
    REQUEST_CALL_ANALYTICS({ days: selectedDateRange }).then(({ data }) => {
      if (data.data.length) {
        const chartData: CALL_ANALYTICS[] = data.data;
        const oldToNew = chartData.sort(
          (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf(),
        );
        setCallDurations(
          oldToNew.map((item) => ({
            title: item.date,
            value: Math.round(item.totalCallSeconds / 60),
          })),
        );
        setCallCounts(
          oldToNew.map((item) => ({
            title: item.date,
            value: item.totalCalls,
          })),
        );
      }
    });
  }, [selectedDateRange]);

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:mb-5">
        <MiniChart
          title={t("call_count")}
          chartData={callCounts}
          tooltipExtraText="Çağrı"
          isLoading={isLoading}
        />
        <MiniChart
          title={t("call_duration")}
          chartData={callDurations}
          tooltipExtraText={t("mins")}
          isLoading={isLoading}
        />
        <MiniChart
          title={t("number_of_appointments")}
          tooltipExtraText={t("appointment")}
          chartData={[
            {
              value: 40,
              title: moment().subtract(7, "days").format("dddd"),
            },
            {
              value: 36,
              title: moment().subtract(6, "days").format("dddd"),
            },
            {
              value: 39,
              title: moment().subtract(5, "days").format("dddd"),
            },
            {
              value: 46,
              title: moment().subtract(4, "days").format("dddd"),
            },
            {
              value: 37,
              title: moment().subtract(3, "days").format("dddd"),
            },
            {
              value: 41,
              title: moment().subtract(2, "days").format("dddd"),
            },
            {
              value: 48,
              title: moment().subtract(1, "days").format("dddd"),
            },
            {
              value: 21,
              title: moment().format("dddd"),
            },
          ]}
          isLoading={isLoading}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-5">
        <BarChart
          title={t("interest_of_departments")}
          data={{
            labels: [
              "Dahiliye",
              "Cerrahi",
              "Kadın Doğum",
              "Pediatri",
              "Ortopedi",
              "KBB",
              "Göz",
              "Deri",
              "Nöroloji",
              "Kardiyoloji",
              "Psikiyatri",
              "Gastroenteroloji",
              "Endokrinoloji",
              "Üroloji",
              "Radyoloji",
              "Fizik Tedavi ve Rehabilitasyon",
            ],
            datasets: [
              {
                label: t("no_of_interest"),
                data: [
                  50, 45, 41, 38, 35, 34, 31, 28, 24, 22, 19, 16, 14, 12, 8, 3,
                ],
                backgroundColor: "#00A2E8",
                barThickness: 18,
                borderRadius: 12,
              },
            ],
          }}
          direction="x"
          isLoading={isLoading}
        />
        <BarChart
          title={t("appointment_of_departments")}
          data={{
            labels: [
              "Dahiliye",
              "Cerrahi",
              "Kadın Doğum",
              "Pediatri",
              "Ortopedi",
              "KBB",
              "Göz",
              "Deri",
              "Nöroloji",
              "Kardiyoloji",
              "Psikiyatri",
              "Gastroenteroloji",
              "Endokrinoloji",
              "Üroloji",
              "Radyoloji",
              "Fizik Tedavi ve Rehabilitasyon",
            ],
            datasets: [
              {
                label: t("created_appointment"),
                data: [
                  40, 35, 31, 28, 25, 24, 21, 18, 14, 12, 11, 9, 9, 8, 4, 1,
                ],
                backgroundColor: "#00A2E8",
                barThickness: 18,
                borderRadius: 12,
              },
            ],
          }}
          direction="x"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default HospitalAnalytics;
